// Routes for lite router, needs to be in a separate file to avoid circular imports which make HMR performance bad, like
//  /src/lite/views/Collection/Collection.tsx -> /src/lite/views/ContentBlock/EditContentBlockModal.tsx -> /src/lite/views/ContentBlock/LinkToCollectionComponent.tsx -> /src/helpers/hooks/useStorefrontAPIClient.tsx -> /src/helpers/hooks/app/useAuthorizedApi.tsx -> /src/api/authorizedApi.ts -> /src/lite/DepictLiteRouter.tsx -> /src/lite/views/Collection/Collection.tsx

export enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  DASHBOARD = "/:merchantId/dashboard",
  DEFAULT_REDIRECT = "/:merchantId",
  GETTING_STARTED = "/:merchantId/getting-started",
  COLLECTIONS = "/:merchantId/collections",
  COLLECTION = "/:merchantId/collections/:collectionId",
  BOOST_AND_BURY = "/:merchantId/boost-and-bury",
  SETTINGS = "/:merchantId/settings",
  MULTI_STORE_CONNECTIONS = "/:merchantId/settings/connections",
  NO_MERCHANTS = "/no-merchants",
  NOT_FOUND = "/404",
  MAINTENANCE = "/maintenance",
  UNAUTHORIZED = "/unauthorized",
}
