import {
  ReactElement,
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useState,
} from "react";
import { CollectionOrderBy, LiteDashboardCollectionDto } from "src/api/types";
import { css, useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { Collection, CollectionBadge } from "./CollectionTable.types";
import {
  CaretDown,
  ChartLine,
  CursorClick,
  DotsThreeVertical,
  DropboxLogo,
  Lightning,
  Pause,
  Star,
  Timer,
} from "@phosphor-icons/react";
import useDashboardCollectionData from "../../Dashboard/useDashboardCollectionData";
import { Badge } from "../../../../alignUI/Badges/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../alignUI/Tooltip/Tooltip";
import useGetCollectionsOutOfStockStatus from "../useGetCollectionsOutOfStockStatus";
import { useMultiStore } from "src/lite/helpers/useMultiStore";
import { Button } from "src/alignUI/Button/Button";
import { Search } from "src/alignUI/Search/Search";
import { CollectionImage } from "../CollectionImage";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import useMerchant from "../../../../helpers/hooks/app/useMerchant";
import useIsMobile from "src/helpers/hooks/useIsMobile";
import { components } from "../../../../api/generated/openapi";

const ClickthroughRateModal = lazy(
  () => import("../../Dashboard/Modal/ClickthroughRateModal")
);

const TOP_N = 10;

const collectionOrderByValues: { name: string; key: CollectionOrderBy }[] = [
  { name: "Recent", key: "updated_at" },
  { name: "Popular", key: "popular" },
  { name: "Name A-Z", key: "name_a_z" },
];

const fakeDataWhileLoading: Collection[] = Array.from(
  { length: 12 },
  (_, index) => {
    const collection: Collection = {
      coverImages: undefined,
      productImages: undefined,
      title: "",
      numProducts: 0,
      sorting: "BEST_SELLING",
      depict: true,
      id: index.toString(),
      numPins: 0,
      badges: [],
      missingInSubStores: [],
      views: null,
      clicks: null,
      clickthrough_rate: null,
    };

    return collection;
  }
);

function PlusBadge({ badges }: { badges: string[] }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge
          _style={"lighter"}
          text={"+" + badges.length.toString()}
          color="gray"
          size="medium"
          className="lift"
        />
      </TooltipTrigger>
      <TooltipContent>{badges.join(" | ")}</TooltipContent>
    </Tooltip>
  );
}

function getBadgeNames(badges: CollectionBadge[]): string[] {
  const badgeNames: string[] = [];

  badges.forEach((badge) => {
    switch (badge.type) {
      case "live":
        badgeNames.push(`Live`);
        break;
      case "conversion_rate":
        badgeNames.push(`Converting`);
        break;
      case "popular":
        badgeNames.push(`Popular`);
        break;
      case "out_of_stock":
        badgeNames.push(`Issue stock`);
        break;
    }
  });
  return badgeNames;
}

function BadgeGroup({ badges }: { badges: CollectionBadge[] }) {
  const elements: ReactElement[] = [];
  for (let i = 0; i < 2; i++) {
    if (!badges[i]) {
      break;
    }
    elements.push(
      <CollectionBadgeComponent badge={badges[i]} key={badges[i].type} />
    );
  }
  if (badges.length > 2) {
    elements.push(
      <PlusBadge badges={getBadgeNames(badges.slice(2))} key={"overflow"} />
    );
  }
  return elements;
}

function CollectionBadgeComponent({ badge }: { badge: CollectionBadge }) {
  switch (badge.type) {
    case "live":
      return (
        <Badge
          _style="light"
          color="green"
          icon={<Lightning size={16} weight="fill" />}
          text="Live"
          size="medium"
        />
      );
    case "conversion_rate":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<CursorClick size={16} weight={"fill"} />}
              color="blue"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            Rank {badge.rank} with {badge.conversionRate.toFixed(0)}%
            clickthrough rate
          </TooltipContent>
        </Tooltip>
      );
    case "popular":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<Star size={16} weight={"fill"} />}
              color="blue"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            Rank {badge.rank} with {badge.clicks} clicks
          </TooltipContent>
        </Tooltip>
      );
    case "out_of_stock":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<DropboxLogo size={16} weight={"fill"} />}
              color="red"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            {badge.mainProductIds.length} products out of stock in the first 16
            products
          </TooltipContent>
        </Tooltip>
      );
  }
}

interface CollectionTableProps {
  collections: LiteDashboardCollectionDto[];
  isLoading: boolean;
  noRowsOverlayComponent?: JSX.Element;
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
  orderBy: CollectionOrderBy;
  loadMoreRef: (element: Element | null) => void;
  onUnPublishDepict: (collectionId: string) => void;
  collectionsData:
    | components["schemas"]["LiteDashboardCollectionDto"][]
    | undefined;
  collectionsDataLoading: boolean;
  collectionTopClickDataQuery: ReturnType<typeof useDashboardCollectionData>;
}

export const CollectionTable = ({
  collections,
  isLoading,
  onSearch,
  setOrderBy,
  orderBy,
  loadMoreRef,
  noRowsOverlayComponent: noRowsFallback,
  onUnPublishDepict,
  collectionsData,
  collectionsDataLoading,
  collectionTopClickDataQuery,
}: CollectionTableProps) => {
  const { byShopId: multiStores } = useMultiStore();
  const { merchant } = useMerchant();
  const toDate = useMemo(() => new Date(), []);
  const fromDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date;
  }, []);

  const topPopularCollections = useMemo(
    () =>
      collectionTopClickDataQuery.data?.pages
        .flatMap((page) => page.collections)
        .slice(0, TOP_N)
        .reduce((mem, collection, index) => {
          mem.set(collection.collection_id, {
            type: "popular",
            rank: index + 1,
            clicks: collection.selected_period_data.clicks || 0,
          });
          return mem;
        }, new Map<string, CollectionBadge>()),
    [collectionTopClickDataQuery.data]
  );

  const collectionTopConversionDataQuery = useDashboardCollectionData({
    from_date: fromDate,
    to_date: toDate,
    order_by: "clickthrough_rate_desc",
    page_size: TOP_N,
  });
  const topConvertingCollections = useMemo(
    () =>
      collectionTopConversionDataQuery.data?.pages
        .flatMap((page) => page.collections)
        .slice(0, TOP_N)
        .reduce((mem, collection, index) => {
          if (!collection.selected_period_data.clickthrough_rate) return mem;
          mem.set(collection.collection_id, {
            type: "conversion_rate",
            rank: index + 1,
            conversionRate: collection.selected_period_data.clickthrough_rate,
          });
          return mem;
        }, new Map<string, CollectionBadge>()),
    [collectionTopConversionDataQuery.data]
  );

  const { data: outOfStockStatus } = useGetCollectionsOutOfStockStatus();

  const data = useMemo(() => {
    if (isLoading) {
      return fakeDataWhileLoading;
    }

    return collections.map((collection) => {
      const syncBackToShopify = collection.sync_back_to_shopify;
      const sorting = syncBackToShopify
        ? collection.sort_order
        : collection.shopify_sort_order;

      const badges = [
        topConvertingCollections?.get(collection.collection_id),
        topPopularCollections?.get(collection.collection_id),
      ].filter((badge): badge is CollectionBadge => !!badge);

      const productsOutOfStock = outOfStockStatus?.[collection.collection_id];

      if (productsOutOfStock && productsOutOfStock.length > 0) {
        badges.push({
          type: "out_of_stock",
          mainProductIds: productsOutOfStock,
        });
      }

      const missingShopIds = Object.keys(multiStores ?? {}).filter(
        (shopId) => !collection.connected_shop_ids?.includes(shopId)
      );

      if (syncBackToShopify) {
        badges.unshift({ type: "live" });
      }

      const _collection: Collection = {
        id: collection.collection_id,
        coverImages: collection.image_urls,
        productImages: collection.product_images,
        title: collection.title,
        sorting: sorting,
        depict: syncBackToShopify,

        numProducts: collection.n_products,
        numPins: syncBackToShopify
          ? collection.n_pinned_main_product_ids
          : null,

        badges,
        missingInSubStores:
          missingShopIds && multiStores
            ? (missingShopIds
                .map((shopId) => multiStores[shopId]?.shopify_base_url)
                .filter((d) => d) as string[])
            : [],
        views: collection.selected_period_data.views,
        clicks: collection.selected_period_data.clicks,
        clickthrough_rate: collection.selected_period_data.clickthrough_rate,
      };

      return _collection;
    });
  }, [
    collections,
    isLoading,
    multiStores,
    outOfStockStatus,
    topConvertingCollections,
    topPopularCollections,
  ]);

  const [selectedDashboardCollectionId, setSelectedDashboardCollectionId] =
    useState<string | undefined>(undefined);

  const onClose = useCallback(
    () => void setSelectedDashboardCollectionId(undefined),
    [setSelectedDashboardCollectionId]
  );

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const installTimestamp = merchant?.start_timestamp ?? 0;
  const installedMoreThanADay = installTimestamp < currentTimestamp - 86400;

  return (
    <>
      <Rows
        onSearch={onSearch}
        collections={data}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        isLoading={isLoading}
        loadMoreRef={loadMoreRef}
        setSelectedCollectionId={setSelectedDashboardCollectionId}
        onUnPublishDepict={onUnPublishDepict}
        noRowsFallback={noRowsFallback}
        showDataPlaceHolder={!installedMoreThanADay}
      />
      <Suspense>
        {selectedDashboardCollectionId && (
          <ClickthroughRateModal
            onSetSelectedCollectionId={setSelectedDashboardCollectionId}
            selectedCollectionId={selectedDashboardCollectionId}
            open={selectedDashboardCollectionId !== undefined}
            onClose={onClose}
            collectionsData={collectionsData}
            collectionsDataLoading={collectionsDataLoading}
          />
        )}
      </Suspense>
    </>
  );
};

function Rows({
  collections,
  onSearch,
  setOrderBy,
  orderBy,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  noRowsFallback: noRowsFallback,
  showDataPlaceHolder,
  setSelectedCollectionId,
}: {
  collections: Collection[];
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
  orderBy: CollectionOrderBy;
  isLoading: boolean;
  loadMoreRef: (element: Element | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  noRowsFallback?: JSX.Element;
  showDataPlaceHolder: boolean;
}) {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <div
      css={(theme) => css`
        display: grid;
        grid-template-columns: 120px minmax(0, 1fr) 150px 80px 80px 80px 80px auto;
        @media (max-width: ${theme.breakpoints.sm}px) {
          grid-template-columns: 86px minmax(0, 1fr) 80px auto;
        }
        align-items: center;
      `}
    >
      <div
        css={css`
          grid-column: 1/-1;
          display: grid;
          grid-template-columns: subgrid;
          align-items: center;
          justify-content: center;

          // Sticky header
          @media (min-width: ${theme.breakpoints.sm + 1}px) {
            position: sticky;
            top: 0;
            z-index: 10;
            background: ${theme.colors.bg["white-0"]};
          }
        `}
      >
        <div
          css={[
            (theme) => css`
              grid-column: span 2;
              @media (max-width: ${theme.breakpoints.sm}px) {
                grid-column: 1/-1;
              }
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: ${isMobile ? "space-between" : null};
              padding-left: 5px;
            `,
          ]}
        >
          <OrderByDropDown orderBy={orderBy} setOrderBy={setOrderBy} />
          <div
            css={(theme) => css`
              @media (max-width: ${theme.breakpoints.sm}px) {
                justify-content: end;
              }
            `}
          >
            <Search openWidth={120} onSearch={onSearch} />
          </div>
        </div>

        {!isMobile && (
          <>
            <HeaderElement title={"Status"} />
            <HeaderElement title={"Products"} />
            <HeaderElement title={"Views"} />
            <HeaderElement title={"Clicks"} />
            <HeaderElement title={"CTR"} />
          </>
        )}
      </div>

      {collections.length === 0 ? (
        noRowsFallback
      ) : (
        <div
          css={(theme) => css`
            border: 1px solid ${theme.colors.stroke["soft-200"]};
            border-radius: 16px;
            margin: 8px 0;
            padding: 8px;

            display: grid;
            grid-template-columns: subgrid;
            grid-column: span 8;
          `}
          ref={loadMoreRef}
        >
          {collections.map((collection) => (
            <Row
              key={collection.id}
              collection={collection}
              isLoading={isLoading}
              setSelectedCollectionId={setSelectedCollectionId}
              onUnPublishDepict={onUnPublishDepict}
              showDataPlaceHolder={showDataPlaceHolder}
            />
          ))}
          <span ref={loadMoreRef}></span>
        </div>
      )}
    </div>
  );
}

function Row({
  collection,
  isLoading,
  onUnPublishDepict,
  showDataPlaceHolder,
  setSelectedCollectionId,
}: {
  collection: Collection;
  isLoading: boolean;
  onUnPublishDepict: (collectionId: string) => void;
  showDataPlaceHolder: boolean;
  setSelectedCollectionId: (collectionId: string) => void;
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <>
      <div
        css={css`
          display: grid;
          align-items: center;
          grid-column: span 8;
          grid-template-columns: subgrid;
          padding: 4px;
          border-radius: 8px;

          .collection-link {
            color: ${theme.colors.text["strong-950"]};
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:has(.collection-link:hover) {
            background: ${theme.colors.bg["weak-50"]};
          }

          /* For link overlay */
          position: relative;
          button,
          .lift {
            z-index: 1;
            position: relative;
          }

          :not(:last-of-type):after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 98px);
            border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
          }
        `}
      >
        <CollectionImage
          showPlaceholder={isLoading}
          coverImages={collection.coverImages}
          productImages={collection.productImages}
          size="small"
        />
        <div
          css={css`
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          <Link
            to={collection.id}
            className="collection-link"
            css={[
              css`
                &:before {
                  position: absolute;
                  display: block;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  content: "";
                }
              `,
              (theme) => theme.typography["label-m"],
            ]}
          >
            {collection.title}
          </Link>
        </div>
        {isMobile && (
          <div>
            {collection.depict && (
              <Badge
                _style="light"
                color="green"
                icon={<Lightning size={16} weight="fill" />}
                text="Live"
                size="medium"
              />
            )}
          </div>
        )}

        {!isMobile && (
          <>
            {/* Status */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                  display: flex;
                  gap: 4px;
                `,
              ]}
            >
              {!isLoading && (
                <>
                  <BadgeGroup badges={collection.badges} />
                </>
              )}
            </div>

            {/* Products */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                `,
              ]}
            >
              {!isLoading ? collection.numProducts : null}
            </div>

            {/* Views */}
            {!showDataPlaceHolder ? (
              <>
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {collection.views}
                </div>
                {/* Clicks */}
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {collection.clicks}
                </div>
                {/* CTR */}
                <div
                  css={[
                    (theme) => theme.typography["label-s"],
                    css`
                      padding: 12px 8px;
                    `,
                  ]}
                >
                  {isLoading
                    ? null
                    : collection.clickthrough_rate
                      ? collection.clickthrough_rate.toFixed(0) + "%"
                      : 0 + "%"}
                </div>
              </>
            ) : (
              <>
                {!isLoading && (
                  <div
                    css={[
                      (theme) => theme.typography["label-s"],
                      css`
                        padding: 12px 8px;
                        grid-column: span 3;
                        color: ${theme.colors.text["soft-400"]};
                        display: flex;
                        align-items: center;
                        gap: 6px;
                      `,
                    ]}
                  >
                    <Timer size={20} />
                    Collecting views & clicks.
                  </div>
                )}
              </>
            )}
          </>
        )}

        {/* Actions */}
        {!isLoading && (
          <div
            css={css`
              display: flex;
              justify-content: end;
            `}
          >
            <Button
              _style="ghost"
              variant="neutral"
              size="small"
              onClick={() => {
                setSelectedCollectionId(collection.id);
              }}
            >
              <ChartLine size={20} />
            </Button>
            {!isMobile && (
              <OverFlowMenuDropDown
                onUnPublishDepict={
                  collection.depict
                    ? () => onUnPublishDepict(collection.id)
                    : undefined
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

const HeaderElement = ({ title }: { title: string }) => {
  return (
    <>
      <div
        css={(theme) => [
          theme.typography["paragraph-s"],
          css`
            padding: 10px 8px;
            color: ${theme.colors.text["sub-600"]};
          `,
        ]}
      >
        {title}
      </div>
    </>
  );
};

const OrderByDropDown = ({
  orderBy,
  setOrderBy,
}: {
  orderBy: CollectionOrderBy;
  setOrderBy: (orderBy: CollectionOrderBy) => void;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  function getOrderByName(orderBy: CollectionOrderBy) {
    return collectionOrderByValues.find((value) => value.key === orderBy)?.name;
  }

  return (
    <DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
      <DropdownMenuTrigger asChild>
        <Button
          _style="ghost"
          variant="neutral"
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <div
            css={(theme) => [
              theme.typography["h6"],
              css`
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 6px;
              `,
            ]}
          >
            {getOrderByName(orderBy)}
            <CaretDown size={20} weight="bold" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>SORT BY</DropdownMenuLabel>
        <DropdownMenuRadioGroup
          value={orderBy}
          onValueChange={(value) => {
            setOrderBy(value as CollectionOrderBy);
          }}
        >
          {collectionOrderByValues.map((collectionOrderByValue) => (
            <DropdownMenuRadioItem
              key={collectionOrderByValue.name}
              value={collectionOrderByValue.key}
            >
              {collectionOrderByValue.name}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const OverFlowMenuDropDown = ({
  onUnPublishDepict,
}: {
  onUnPublishDepict: (() => void) | undefined;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const theme = useTheme();

  return (
    <DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
      <DropdownMenuTrigger asChild>
        <Button
          _style="ghost"
          variant="neutral"
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <DotsThreeVertical size={20} weight="bold" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Button
            css={[
              theme.typography["paragraph-s"],
              css`
                display: flex;
                align-items: center;
                justify-content: left;
              `,
            ]}
            disabled={!onUnPublishDepict}
            onClick={onUnPublishDepict}
            variant={"danger"}
            _style={"ghost"}
          >
            <Pause size={20} />
            Un-publish
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
