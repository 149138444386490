import { useContext, useEffect } from "react";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";
import { Merchant } from "src/api/types";
import { LEGACY_MERCHANTS } from "../../../constants/legacyMerchants";
import { useOptionalMerchantId } from "./useMerchantId";

type UseOptionalMerchantReturn = {
  loading: boolean;
  merchant: Merchant | undefined;
  merchantOptions: Merchant[];
};

/**
 * useOptionalMerchant is a React hook that provides a convenient way to access the
 * MerchantContext. This differs from the useMerchant hook in that it does not throw an error
 * if the merchant is not found. (Useful if you don't know if PortalRouter has redirected yet)
 */
function useOptionalMerchant(): UseOptionalMerchantReturn {
  const { merchantId } = useOptionalMerchantId();
  const authorizedMerchantsContext = useContext(AuthorizedMerchantsContext);

  useEffect(() => {
    if (
      merchantId &&
      LEGACY_MERCHANTS.includes(merchantId) &&
      window.location.hostname !== "localhost"
    ) {
      // Route to legacy portal at portal.depict.ai
      window.location.href = `https://portal.depict.ai/`;
    }
  }, [merchantId]);

  const { authorizedMerchants, loading } = authorizedMerchantsContext;

  if (authorizedMerchants === null) {
    throw new Error(
      "useOptionalMerchant used outside without AuthorizedMerchantsContext"
    );
  }

  if (authorizedMerchants.length === 0) {
    return { loading, merchant: undefined, merchantOptions: [] };
  }

  if (!merchantId) {
    const merchant =
      authorizedMerchants.length === 1
        ? // If we only have access to one merchant, that's the one we get! Even if the merchant isn't onboarded, that just means it needs to be onboarded!
          authorizedMerchants[0]
        : // If we have access to multiple merchants, we're a super user, and we want to get one that currently fully access the portal so we can click around and test things.
          authorizedMerchants.find(
            (merchant) =>
              merchant.shopify_app_installed &&
              merchant.has_completed_onboarding
          );

    return {
      loading,
      merchant,
      merchantOptions: authorizedMerchants,
    };
  }

  const merchant = authorizedMerchants.find(
    (merchant) => merchant.id === merchantId
  );

  if (!merchant) {
    throw new Response(
      "merchantId parameter does not match an authorized merchant",
      { status: 404, statusText: "Not Found" }
    );
  }

  return {
    merchant: merchant,
    merchantOptions: authorizedMerchants,
    loading,
  } satisfies UseOptionalMerchantReturn;
}

export default useOptionalMerchant;
