import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { getLiteQueryKey, QueryId } from "src/lite/queries";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { ActivationStatus } from "src/api/types";

const getFeatureActivationStatus = async (
  api: AuthorizedApi,
  merchantId: string
): Promise<ActivationStatus> => {
  const response = await api.GET(`/dashboard/feature-activation-status`, {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
  });

  return getData(response);
};

export const useFeatureActivationStatus = () => {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();

  const query = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetFeatureActivationStatus, {
      merchantId,
    }),
    queryFn: () => getFeatureActivationStatus(api!, merchantId),
    enabled: !!api,
  });

  return query;
};

export default useFeatureActivationStatus;
