import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

const ForceHoverContext = createContext<
  | undefined
  | {
      forceHoverOn: Set<string>;
      setForceHoverOn: React.Dispatch<React.SetStateAction<Set<string>>>;
    }
>(undefined);

/**
 * Provider to communicate between product cards that a product duplicate (or original) should show a hover effect because it's linked card is hovered.
 */
export function ForceHoverProvider({
  children,
}: PropsWithChildren<Record<never, never>>) {
  const [forceHoverOn, setForceHoverOn] = useState(new Set<string>());

  return (
    <ForceHoverContext.Provider value={{ forceHoverOn, setForceHoverOn }}>
      {children}
    </ForceHoverContext.Provider>
  );
}

export function useForceHover() {
  return useContext(ForceHoverContext);
}
