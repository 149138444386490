import { NavMenu } from "@shopify/app-bridge-react";
import { Link } from "react-router-dom";
import { buildPathWithMerchantId } from "src/lite/DepictLiteRouter";
import { ROUTES } from "src/lite/DepictLiteRouter-ROUTES";

export function ShopifyNavMenu({ merchantId }: { merchantId: string }) {
  return (
    <NavMenu>
      <Link rel="home" to={ROUTES.HOME}>
        Depict
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.DASHBOARD, merchantId)}>
        Dashboard
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchantId)}>
        Collections
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.BOOST_AND_BURY, merchantId)}>
        Boosts
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.SETTINGS, merchantId)}>
        Settings
      </Link>
    </NavMenu>
  );
}
