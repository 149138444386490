import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { css, useTheme } from "@emotion/react";
import { useState } from "react";
import { Button } from "../Button/Button";
import { PopoverClose } from "@radix-ui/react-popover";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

export default function DatePickerPopOverContent({
  from,
  to,
  onSelect,
}: {
  from?: Date;
  to?: Date;
  onSelect: (from: Date, to: Date) => void;
}) {
  const theme = useTheme();
  const [fromDate, setFromDate] = useState<Date | undefined>(from);
  const [toDate, setToDate] = useState<Date | undefined>(to);
  return (
    <div
      css={css`
        padding: 4px;
        border-radius: 20px;
        border: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};
        box-shadow: ${theme.shadows.regular.medium};
      `}
    >
      <DayPicker
        showOutsideDays
        css={css`
          ${theme.typography["label-s"]}
          color: ${theme.colors.text["sub-600"]};
          .rdp-caption {
            height: 36px;
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;
            border-radius: 8px;
            background: ${theme.colors.bg["weak-50"]};
          }
          .rdp-day {
            border-radius: 8px;
            &:hover {
              background: ${theme.colors.bg["weak-50"]} !important;
            }
          }
          .rdp-caption_label {
            ${theme.typography["label-s"]}
            color: ${theme.colors.text["sub-600"]};
          }
          .rdp-day_selected {
            background-color: ${theme.colors.primary.base};
            border-radius: 8px;
            &:hover {
              background: ${theme.colors.primary.dark} !important;
            }
          }
          .rdp-day_range_end.rdp-day_range_start {
            border-radius: 8px;
          }
          .rdp-day_range_middle {
            background-color: ${theme.colors.primary.alpha10};
            color: ${theme.colors.primary.base};
            border-radius: 0;
          }
          .rdp-cell {
            padding-top: 8px;
            &:first-child .rdp-day_range_middle {
              border-radius: 8px 0 0 8px;
            }
            &:last-child .rdp-day_range_middle {
              border-radius: 0 8px 8px 0;
            }
          }
          .rdp-day_outside {
            color: ${theme.colors.text["disabled-300"]};
          }
          .rdp-head_cell {
            padding-top: 8px;
            ${theme.typography["label-s"]}
            color: ${theme.colors.text["soft-400"]};
          }
          .rdp-nav {
            display: flex;
            align-items: center;
            .rdp-button {
              border-radius: 6px;
              background: ${theme.colors.bg["white-0"]};
              box-shadow: 0 1px 2px 0 rgba(10, 13, 20, 0.03);
              width: 24px;
              height: 24px;
              &:hover {
                background: ${theme.colors.bg["sub-200"]} !important;
              }
            }
            .rdp-nav_button_previous {
              position: absolute;
              left: 6px !important;
            }
            .rdp-nav_button_next {
              position: absolute;
              right: 6px;
            }
          }
        `}
        components={{
          IconLeft: ({ ...props }) => <CaretLeft size={16} />,
          IconRight: ({ ...props }) => <CaretRight size={16} />,
        }}
        mode="range"
        selected={{ from: fromDate, to: toDate }}
        onSelect={(range) => {
          setFromDate(range?.from);
          setToDate(range?.to);
        }}
        toDate={new Date()}
      />
      <div
        css={css`
          padding: 16px;
          border-top: 1px solid ${theme.colors.stroke["soft-200"]};
        `}
      >
        {fromDate && toDate && (
          <div
            css={[
              theme.typography["paragraph-s"],
              css`
                margin-bottom: 16px;
              `,
            ]}
          >
            Range:{" "}
            <span css={[theme.typography["label-s"]]}>
              {fromDate.toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}{" "}
              -{" "}
              {toDate.toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </span>
          </div>
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <PopoverClose asChild>
            <Button fullWidth variant={"neutral"} _style={"stroke"}>
              Cancel
            </Button>
          </PopoverClose>
          <PopoverClose asChild>
            <Button
              fullWidth
              variant={"primary"}
              _style={"filled"}
              onClick={() => onSelect(fromDate!, toDate!)}
              disabled={fromDate === undefined || toDate === undefined}
            >
              Apply
            </Button>
          </PopoverClose>
        </div>
      </div>
    </div>
  );
}
