import SpinnerPlaceholder from "src/assets/img/depict_spinner_fade.svg";

const style = {
  height: 42,
  width: 42,
};

export const DepictSpinner = () => {
  return <img src={SpinnerPlaceholder} style={style} />;
};
