import { Interpolation, Theme, css } from "@emotion/react";
import React from "react";

const columnsToPixels = (columns: number) => (1440 / 12) * columns;

const Container = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{
    columns?: number;
    customCss?: Interpolation<Theme>;
  }>
>(({ columns = 10, children, customCss }, ref) => {
  return (
    <main
      ref={ref}
      css={[
        css`
          width: 100%;
          max-width: ${columnsToPixels(columns)}px;
        `,
        customCss,
      ]}
    >
      {children}
    </main>
  );
});

export default Container;
