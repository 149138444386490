import { css, useTheme } from "@emotion/react";
import { useIsDarkMode } from "../../../../../helpers/hooks/app/useIsDarkMode";
import {
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../../../../alignUI/Modal/Modal";
import { Button } from "../../../../../alignUI/Button/Button";
import EditImageSettings from "../EditImageSettings/EditImageSettings.png";
import EditImageSettingsDark from "../EditImageSettings/EditImageSettingsDark.png";

export const EditImageSettingsModalContent = ({
  onConfirm,
}: {
  onConfirm: () => void;
}) => {
  const isDarkMode = useIsDarkMode();
  const theme = useTheme();

  const imageSrc = isDarkMode ? EditImageSettingsDark : EditImageSettings;

  return (
    <ModalContent
      size={"medium"}
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      `}
    >
      <ModalHeader
        css={css`
          border-bottom: none;
          min-height: 52px;
        `}
      ></ModalHeader>
      <div
        css={css`
          flex: 1;
          padding: 32px;
          overflow-y: auto;
        `}
      >
        <h5
          css={[
            theme.typography["h5"],
            css`
              color: ${theme.colors.text["strong-950"]};
              text-align: center;
              margin-bottom: 4px;
            `,
          ]}
        >
          Change product image
        </h5>
        <p
          css={[
            theme.typography["paragraph-m"],
            css`
              color: ${theme.colors.text["soft-400"]};
              text-align: center;
              margin-bottom: 32px;
            `,
          ]}
        >
          Get creative and edit product images in a specific collection.
        </p>
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          `}
        >
          <img
            src={imageSrc}
            alt="Edit product images"
            css={css`
              width: 100%;
              height: auto;
              max-width: 596px;
              max-height: 100%;
              margin-left: -32px;
              margin-right: -32px;
            `}
          />
        </div>
      </div>
      <ModalFooter>
        <ModalClose asChild>
          <Button _style="stroke" variant="neutral">
            Cancel
          </Button>
        </ModalClose>
        <Button _style="filled" variant="primary" onClick={onConfirm}>
          Pick a collection to try
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
