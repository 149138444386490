import useMerchant from "src/helpers/hooks/app/useMerchant";
import { useConfigValidity } from "./useGetConfigValidity";
import Banner from "src/alignUI/Banner/Banner";
import { DepictLink } from "../../../alignUI/DepictLink/DepictLink";
import { useIntercom } from "react-use-intercom";
import { css, useTheme } from "@emotion/react";

const MaybeInvalidConfigBanner = () => {
  const { merchantId } = useMerchant();

  const configValidity = useConfigValidity({
    merchantId,
  });

  const intercom = useIntercom();
  const theme = useTheme();

  if (
    configValidity.isLoading ||
    !configValidity.data ||
    configValidity.data.state === "all_good"
  ) {
    return null;
  }

  const getStatusText = () => {
    if (!configValidity.data) return "Something is wrong";

    switch (configValidity.data.state) {
      case "config_invalid":
        return "Depict is unable to update your store.";
      case "payment_required":
        return "Your subscription is expired. Please update your payment method.";
      default:
        return "Something is wrong";
    }
  };

  return (
    <Banner
      expand={true}
      status={"error"}
      _style={"filled"}
      title={"Something is wrong"}
      description={getStatusText()}
      component={
        <div
          css={css`
            margin-left: auto;
            @media (max-width: ${theme.breakpoints.sm}px) {
              margin-left: 0;
            }
          `}
        >
          <DepictLink
            size="Medium"
            onClick={() => {
              intercom.show();
            }}
            underline={true}
            _style="White"
          >
            Contact support
          </DepictLink>
        </div>
      }
    />
  );
};

export default MaybeInvalidConfigBanner;
