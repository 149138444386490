import { css } from "@emotion/react";
import showcaseVideoPoster from "src/assets/img/Product_Splash_Spedup_S_Dark_Poster.jpg?url";
import { darkColors } from "../../../../theme";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";

export default function Showcase() {
  const isMobile = useIsMobile();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        overflow: scroll-y;
        row-gap: 40px;
        justify-content: ${isMobile ? "flex-start" : "center"};
        background: ${darkColors.bg["white-0"]};
        color: ${darkColors.text["strong-950"]};
      `}
    >
      <h1
        css={[
          (theme) => theme.typography.h1,
          css`
            text-align: center;
            margin-top: 32px;
            color: ${darkColors.text["strong-950"]};
          `,
        ]}
      >
        Build stunning
        <br />
        collections
        <span
          css={css`
            color: ${darkColors.primary.orange};
          `}
        >
          .
        </span>
      </h1>

      <video
        src="https://platform.depict.ai/static/Product_Splash_Spedup_S_dark.mp4"
        poster={showcaseVideoPoster}
        width="550"
        muted
        css={css`
          max-width: 550px;
          aspect-ratio: 1;
          width: 100%;
          mask: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1) 0,
              rgba(0, 0, 0, 1) 60%,
              rgba(0, 0, 0, 0) 95%,
              rgba(0, 0, 0, 0) 0
            )
            100% 50% / 100% 100% repeat-x;
        `}
        autoPlay
        loop
      />
    </div>
  );
}
