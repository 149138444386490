import { type ListItemSupported } from "src/types/components";
import { type ListItemIdKey } from "src/types/configuration";

interface ApiClientConfig {
  baseUrl: string;
}

interface Config extends Object {
  sentry: {
    dsn: string;
    org?: string;
    project?: string;
  };
  auth0: {
    domain: string;
    clientId: string;
  };
  segment: {
    key: string;
  };
  intercom: {
    appId: string;
  };
  monitoring: {
    enabled: boolean;
  };
  gitSha: string | undefined;
  useLocalStorageAccessToken: boolean;
  devMode: boolean;
  publicUrl: string;
  apiClient: ApiClientConfig;
  const: {
    [key: string]: any;
  };
  publicViews: string[];
  plotColors: string[];
  pointsInMiniGraph: number;
  configurationListItemTypeToId: {
    [key in ListItemSupported]: ListItemIdKey;
  };
  hotjar: {
    id: number;
    version: number;
  };
  postHog: {
    apiKey: string;
    host: string;
  };
  shopifyAppName: string;
}

const config: Config = {
  sentry: {
    dsn:
      import.meta.env.VITE_SENTRY_DSN ??
      "https://b52fbe1a581bdf5f9f61a895faa83653@o464891.ingest.us.sentry.io/4506382334164992",
    org: import.meta.env.VITE_SENTRY_ORG,
    project: import.meta.env.VITE_SENTRY_PROJECT,
  },
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN ?? "depict.eu.auth0.com",
    clientId:
      import.meta.env.VITE_AUTH0_CLIENT_ID ??
      "v4rBBuOn19vgSroPGhrTI13AtIFUUhTb",
  },
  segment: {
    key: import.meta.env.VITE_SEGMENT_KEY ?? "TN2YttNTqKeUW1zGB3FvLdZDKbsHwZQ8",
  },
  intercom: {
    appId: import.meta.env.VITE_INTERCOM_APP_ID ?? "sxdorqr2",
  },
  monitoring: {
    enabled:
      (window.location.hostname !== "localhost" &&
        process.env.NODE_ENV !== "development") ||
      import.meta.env.VITE_FORCE_MONITORING === "true",
  },
  gitSha: import.meta.env.VITE_GITSHA,
  publicUrl: import.meta.env.VITE_PUBLIC_URL ?? "/",
  apiClient: {
    // Embedded uses platform.depict.ai, standalone uses lite.depict.ai
    // NOTE: If you change the /api/lite prefix in production, you also need to change the portal-frontend
    // nginx config to forward platform.depict.ai/NEW_PREFIX instead.
    baseUrl: `${window.location.origin}/api/lite`,
  },
  useLocalStorageAccessToken: process.env.NODE_ENV === "development",
  devMode: process.env.NODE_ENV === "development",
  const: {
    defaultCompareOffset: 60 * 60 * 24 * 7, //one week in seconds
    millisecondsInDay: 864e5,
    secondsInDay: 60 * 60 * 24,
    secondsInMonth: 60 * 60 * 24 * (365 / 12),
    secondsInYear: 60 * 60 * 24 * 365,
    bootstrapXsWidth: 576,
    bootstrapSmWidth: 768,
    bootstrapLgWidth: 992,
    bootstrapXlWidth: 1200,
    bootstrapXxlWidth: 1500,
    bootstrapTooltipDelay: { show: 1000, hide: 0 },
    defaultConfigurationDisplayName: "-- No Name --",
    compareSurfaceSuffix: "-depict-compare",
    compareMockTrigger: {
      start: 123456789,
      end: 987654321,
    },
    dateFormatGraph: "%Y-%m-%d",
    dateFormat: "yyyy-MM-dd",
    dateFormatSeparator: "-",
  },
  pointsInMiniGraph: 10,
  publicViews: ["/login", "/recover-password", "/reset-password"],
  plotColors: [
    "#7395D3",
    "#5B9EA6",
    "#9B7CE1",
    "#868689",
    "#333335",
    "#D47C7C",
    "#633EB3",
    "#4695FB",
    "#809E5D",
  ],
  configurationListItemTypeToId: {
    main_product: "main_product_id",
    product: "product_id",
    group: "group_id",
    configuration: "id",
    unknown: "id",
  },
  hotjar: {
    id: import.meta.env.VITE_HOTJAR_ID,
    version: import.meta.env.VITE_HOTJAR_VERSION
      ? parseInt(import.meta.env.VITE_HOTJAR_VERSION)
      : 6,
  },
  postHog: {
    apiKey:
      import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY ??
      "phc_KSTnEtp7dsYroHayAsaSE8PMgHedw6qDea0FvgK4xd9",
    host:
      import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST ??
      "https://eu.i.posthog.com",
  },
  shopifyAppName: import.meta.env.VITE_SHOPIFY_APP_NAME ?? "depict",
};

// print config if localStorage has "depictPrintConfig" set to true
if (localStorage.getItem("depictPrintConfig") === "true")
  console.info({
    config,
    env: {
      DEPICT_LITE: import.meta.env.VITE_DEPICT_LITE,
      BUILD_TARGET: import.meta.env.VITE_BUILD_TARGET,
      BABEL_ENV: import.meta.env.VITE_BABEL_ENV,
      NODE_ENV: import.meta.env.VITE_NODE_ENV,
      PUBLIC_URL: import.meta.env.VITE_PUBLIC_URL,
      CI: import.meta.env.VITE_CI,
      GITSHA: import.meta.env.VITE_GITSHA,
      PORTAL_SERVICE_HOST: import.meta.env.VITE_PORTAL_SERVICE_HOST,
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
      AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
      AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
      SEGMENT_KEY: import.meta.env.VITE_SEGMENT_KEY,
      INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
      FORCE_MONITORING: import.meta.env.VITE_FORCE_MONITORING,
      API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
      API_VERSION: import.meta.env.VITE_API_VERSION,
      USER_EMAIL: import.meta.env.VITE_USER_EMAIL,
      USER_PASSWORD: import.meta.env.VITE_USER_PASSWORD,
      DATA_MOCK: import.meta.env.VITE_DATA_MOCK,
      HOTJAR_VERSION: import.meta.env.VITE_HOTJAR_VERSION,
    },
  });

console.info("App version:", config.gitSha);

export default config;
