import React, { useState } from "react";
import { css, SerializedStyles, useTheme } from "@emotion/react";
import { IconProps } from "@phosphor-icons/react";

type CompactButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  _style: "stroke" | "ghost";
  size: "large" | "medium";
  icon: React.FC<IconProps>;
  hoverIcon?: React.FC<IconProps>;
  forceActive?: boolean;
  customCss?: SerializedStyles;
  onHoverStyle?: boolean;
};

const CompactButton: React.FC<CompactButtonProps> = ({
  _style,
  icon: Icon,
  hoverIcon: HoverIcon,
  size,
  forceActive,
  customCss,
  onHoverStyle,
  ...props
}) => {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const _onHoverStyle = onHoverStyle === undefined ? true : onHoverStyle;

  const sizeStyles = {
    large: css`
      height: 24px;
      width: 24px;
    `,
    medium: css`
      height: 20px;
      width: 20px;
    `,
  };

  const iconSize = size === "large" ? 20 : 18;

  const specificCss =
    _style === "stroke"
      ? css`
          border: 1px solid
            ${forceActive
              ? theme.colors.bg["strong-950"]
              : theme.colors.stroke["soft-200"]};
          box-shadow: 0 1px 2px 0 rgba(10, 13, 20, 0.03);
        `
      : css`
          border: none;
          background: transparent;
        `;

  return (
    <button
      onMouseEnter={() => {
        if (!_onHoverStyle) return;
        return setHover(true);
      }}
      onMouseLeave={() => {
        if (!_onHoverStyle) return;
        return setHover(false);
      }}
      css={[
        sizeStyles[size],
        css`
          border-radius: 6px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;

          color: ${forceActive
            ? theme.colors.icon["white-0"]
            : theme.colors.icon["sub-600"]};

          background: ${forceActive
            ? theme.colors.bg["strong-950"]
            : theme.colors.bg["white-0"]};

          &:active {
            background: ${theme.colors.bg["soft-200"]};
            border-color: ${theme.colors.bg["strong-950"]};
          }

          &:disabled {
            background: transparent;
            cursor: not-allowed;
            border-color: ${theme.colors.icon["disabled-300"]};
            color: ${theme.colors.icon["disabled-300"]};
          }
        `,
        specificCss,
        customCss,
        _onHoverStyle &&
          css`
            &:not(:disabled) {
              &:hover {
                background: ${theme.colors.bg["weak-50"]};
                border-color: ${theme.colors.bg["weak-50"]};
                color: ${theme.colors.icon["strong-950"]};
              }
            }
          `,
      ]}
      {...props}
    >
      {(!HoverIcon || !hover) && <Icon size={iconSize} />}
      {hover && HoverIcon && <HoverIcon size={iconSize} />}
    </button>
  );
};

export default CompactButton;
