import * as Sentry from "@sentry/react";
import { hotjar } from "react-hotjar";
import config from "./config";
import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
} from "react-router-dom";

export const standalone =
  location.origin === "https://lite.depict.ai" ||
  location.hostname === "localhost";

// Only initialize faro if we are not on localhost, or we override it in the .env file
export const monitoringEnabled = config.monitoring.enabled && !standalone;

export const initMonitoring = () => {
  if (!monitoringEnabled) {
    return;
  }

  if (!standalone) {
    // Enable Hotjar unless we're running from the standalone origin only used by admins.
    hotjar.initialize(config.hotjar.id, config.hotjar.version);
  }

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: standalone ? "production_standalone" : "production",
    release: config.gitSha,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 401, 422, [500, 599]],
      }),
      Sentry.browserTracingIntegration({
        // How long to wait after all spans have finished for more data before sending off the trace.
        // We have raised this from the default of 1000ms in an attempt to not miss the LCP event.
        idleTimeout: 5000,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1, //  Capture all transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", config.apiClient.baseUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const setLoggedInUser = (
  email: string,
  merchant: string,
  superUser: boolean
) => {
  Sentry.setUser({
    email: email,
    merchant: merchant,
    superUser: superUser,
  });
};

export function getCreateBrowserRouter() {
  if (monitoringEnabled) {
    return Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  }
  return createBrowserRouter;
}
