import { css } from "@emotion/react";
import queryClient from "src/queries/client";
import { getQueryKey, QueryId } from "src/queries/queries";
import { useIngestionStatus } from "../../../helpers/useIngestionStatus";
import { Logo } from "./Logo";
import { IngestionProgress } from "./IngestionProgress";
import { IngestionDone } from "./IngestionDone";
import Showcase from "./Showcase";
import useInstall from "../../Settings/useInstall";
import { useEffect } from "react";
import useOptionalMerchant from "../../../../helpers/hooks/app/useOptionalMerchant";
import { useMultiStoreValidation } from "src/lite/helpers/useMultiStore";
import { useParams } from "react-router-dom";
import { useAlert } from "src/alignUI/Alert/useAlert/useAlert";
import useIsMobile from "src/helpers/hooks/useIsMobile";

// Fake merchant ID that separates real merchant IDs from "Merchant does not exist and needs to be installed in this view"
export const TRIGGER_INSTALL_PATH_MERCHANT_ID = "install";

const IngestionStep = () => {
  const isMobile = useIsMobile();
  const { merchantId: merchantIdFromParams } = useParams();
  const { merchant } = useOptionalMerchant();
  const { mutate: install, isError } = useInstall();
  const { addAlert } = useAlert();
  const { isLoadingSubStoreInfo } = useMultiStoreValidation();

  useEffect(() => {
    if (
      !merchant?.shopify_app_installed &&
      !isLoadingSubStoreInfo &&
      merchantIdFromParams === TRIGGER_INSTALL_PATH_MERCHANT_ID
    ) {
      // We should only attempt to install if the app is not installed, we were redirected here with the intent of installing, AND we are sure this is not a sub-store that will soon be redirected to the parent store
      install();
    }
  }, [merchant, isLoadingSubStoreInfo, merchantIdFromParams, install]);

  useEffect(() => {
    if (isError) {
      addAlert({
        autoClose: 5000,
        size: "large",
        status: "error",
        styling: "filled",
        text: "An unexpected error occurred. Please refresh the page and try again.",
      });
    }
  }, [isError, addAlert]);

  const { complete, completionPercentage } = useIngestionStatus({
    merchantId: merchant?.id,
    onIngestionComplete: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
      });
    },
  });

  return (
    <div
      css={css`
        display: flex;
        flex-direction: ${isMobile ? `column-reverse` : `row`};
        height: 100%;
        ${isMobile
          ? `padding-top: 20px;
        gap: 32px;
        `
          : ``}
      `}
    >
      <div
        css={(theme) => css`
          background-color: ${theme.colors.bg["weak-50"]};
          flex-grow: 1;
          display: flex;
        `}
      >
        <Showcase />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: ${isMobile ? 16 : 40}px;
          ${isMobile ? `` : `width: 480px;`}
          padding: 0 20px;
        `}
      >
        <Logo />
        {!complete || !merchant ? (
          <IngestionProgress completionPercentage={completionPercentage} />
        ) : (
          <IngestionDone merchantId={merchant.id} />
        )}
      </div>
    </div>
  );
};

export default IngestionStep;
