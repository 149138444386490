import {
  ButtonGroup,
  ButtonGroupItem,
} from "../../../alignUI/ButtonGroup/ButtonGroup";
import { getFromPeriod, Period } from "./util";
import DatePickerPopOverContent from "../../../alignUI/DatePicker/DatePickerPopOverContent";
import * as Popover from "@radix-ui/react-popover";
import { css } from "@emotion/react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../alignUI/Tooltip/Tooltip";

export default function TimeFramePicker({
  now,
  period,
  setPeriod,
}: {
  now: Date;
  period: Period;
  setPeriod: (period: Period) => void;
}) {
  return (
    <ButtonGroup
      type={"single"}
      onValueChange={(value) => {
        switch (value) {
          case "24h":
          case "7d":
          case "4w":
          case "6m":
          case "1y":
            setPeriod({
              type: value,
              fromDate: getFromPeriod(value, now),
              toDate: now,
            });
            break;
          case "custom":
            break;
        }
      }}
      value={period.type}
    >
      <ButtonGroupItem value={"24h"}>24H</ButtonGroupItem>
      <ButtonGroupItem value={"7d"}>7D</ButtonGroupItem>
      <ButtonGroupItem value={"4w"}>4W</ButtonGroupItem>
      <ButtonGroupItem value={"6m"}>6M</ButtonGroupItem>
      <ButtonGroupItem value={"1y"}>1Y</ButtonGroupItem>
      <Popover.Root>
        <Popover.Trigger
          css={css`
            background: none;
            border: none;
            padding: 0;
            margin: 0;
          `}
        >
          <Tooltip>
            <TooltipTrigger
              css={css`
                background: none;
                border: none;
                padding: 0;
                margin: 0;
              `}
            >
              <ButtonGroupItem value={"custom"}>Custom</ButtonGroupItem>
            </TooltipTrigger>
            <TooltipContent>
              {period.type === "custom" ? (
                <span>
                  Range:{" "}
                  {period.fromDate.toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}{" "}
                  -{" "}
                  {period.toDate.toLocaleDateString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              ) : (
                <span>Select a custom date range</span>
              )}
            </TooltipContent>
          </Tooltip>
        </Popover.Trigger>
        <Popover.Content
          sideOffset={8}
          align={"end"}
          css={css`
            z-index: 100;
          `}
        >
          <DatePickerPopOverContent
            from={period.fromDate}
            to={period.toDate}
            onSelect={(from, to) =>
              setPeriod({ type: "custom", fromDate: from, toDate: to })
            }
          />
        </Popover.Content>
      </Popover.Root>
    </ButtonGroup>
  );
}
