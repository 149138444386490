import { useInfiniteQuery } from "@tanstack/react-query";
import { getLiteQueryKey, QueryId } from "../../queries";
import { LiteDashboardCollectionOrderByOption } from "../../../api/types";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../../../helpers/hooks/app/useAuthorizedApi";
import { getData } from "../../../api/authorizedApi";
import { toTzAgnosticIsoDate } from "src/lite/helpers/datetime";

interface DashboardCollectionDataQueryParams {
  from_date: Date;
  to_date: Date;
  order_by: LiteDashboardCollectionOrderByOption;
  page?: number;
  page_size?: number;
  disabled?: boolean;
}

const PAGE_SIZE = 30;

async function apiGetDashboardCollections({
  api,
  merchantId,
  from_date,
  to_date,
  order_by,
  offset,
  page_size,
}: {
  api: AuthorizedApi;
  merchantId: string;
  from_date: string;
  to_date: string;
  order_by: LiteDashboardCollectionOrderByOption;
  offset: number;
  page_size: number;
}) {
  return api.POST("/dashboard/collections", {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
    body: {
      from_date: from_date,
      to_date: to_date,
      order_by: order_by,
      offset: offset,
      page_size: page_size,
    },
  });
}

export default function useDashboardCollectionData({
  from_date,
  to_date,
  order_by,
  page_size = PAGE_SIZE,
  disabled,
}: DashboardCollectionDataQueryParams) {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();

  const fromDateString = toTzAgnosticIsoDate(from_date);
  const toDateString = toTzAgnosticIsoDate(to_date);

  const queryKey = getLiteQueryKey(QueryId.GetDashboardCollectionData, {
    merchantId: merchantId,
    from_date: fromDateString,
    to_date: toDateString,
    order_by: order_by,
    page_size: page_size,
  });

  return useInfiniteQuery({
    queryKey,
    queryFn: async (context: { pageParam: undefined | number }) => {
      if (!api) {
        throw new Error("api is not available");
      }

      const res = await apiGetDashboardCollections({
        api,
        merchantId,
        from_date: fromDateString,
        to_date: toDateString,
        order_by,
        offset: context.pageParam || 0,
        page_size,
      });

      return getData(res);
    },
    enabled: !!api && !disabled,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.has_next) {
        return lastPage.offset;
      }
    },
  });
}
