import React, {
  createContext,
  JSX,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GraphQLClient } from "graphql-request";
import useAuthorizedApi from "./app/useAuthorizedApi";
import { useOptionalMerchantId } from "./app/useMerchantId";

const StorefrontAPIClientContext = createContext<
  undefined | { client?: GraphQLClient }
>(undefined);

export function StorefrontAPIClientProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { api } = useAuthorizedApi();
  const [shopifyData, setShopifyData] = useState<
    undefined | { storefront_api_key: string; store_domain: string }
  >();
  const apiUrl =
    shopifyData && `${shopifyData.store_domain}/api/2024-01/graphql.json`;
  const token = shopifyData?.storefront_api_key;
  const { merchantId } = useOptionalMerchantId();
  const client = useMemo(
    () =>
      shopifyData &&
      new GraphQLClient(apiUrl!, {
        headers: {
          "X-Shopify-Storefront-Access-Token": token!,
        },
      }),
    [shopifyData, apiUrl, token]
  );

  useEffect(() => {
    if (!merchantId) return;
    api
      .GET("/shopify/storefront_api_credentials", {
        params: {
          query: {
            merchant_id: merchantId,
          },
        },
      })
      .then((r) => {
        if (r.data) {
          setShopifyData(r.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId]);

  return (
    <StorefrontAPIClientContext.Provider value={{ client }}>
      {children}
    </StorefrontAPIClientContext.Provider>
  );
}

export function useStorefrontAPIClient() {
  return useContext(StorefrontAPIClientContext)?.client;
}
