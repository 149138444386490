import { useQuery } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import { ShopifyState } from "src/api/types";
import useAuthorizedApi, {
  AuthorizedApi,
} from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getLiteQueryKey } from "src/lite/queries";

const getConfigValidity = async (
  api: AuthorizedApi,
  merchantId: string
): Promise<ShopifyState> => {
  const response = await api.GET(`/shopify/config-validity`, {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
  });

  return getData(response);
};

type ConfigValidityQuery = ReturnType<typeof useConfigValidity>;

interface UseGetConfigValidityProps {
  enabled?: boolean;
  merchantId: string;
}
export const useConfigValidity = (props: UseGetConfigValidityProps) => {
  const { api } = useAuthorizedApi();
  const { merchantId } = props;
  const enabled = props.enabled ?? true;

  const query = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetConfigValidity, {
      merchantId,
    }),
    queryFn: () => {
      if (!api) return;
      return getConfigValidity(api, merchantId);
    },
    enabled: !!api && enabled,
  });

  return query;
};
