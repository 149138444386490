import React from "react";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";

export function StepContainer({
  children,
  extraCss,
  desktopCards,
}: {
  children: React.ReactNode;
  extraCss?: Interpolation<Theme>;
  desktopCards: number;
}) {
  const isMobile = useIsMobile();

  return (
    <section
      css={[
        css`
          --gap: 18px;
          display: flex;
          gap: var(--gap);
          align-self: stretch;
          // Allow shadow to overflow container
          padding-top: 16px;
          padding-bottom: 38px;
          margin-top: -16px;
          margin-bottom: -38px;

          min-height: 0;
          overflow-x: scroll;
          align-items: stretch;
          scroll-snap-type: x mandatory;

          --flex-grow: 1;

          & > * {
            scroll-snap-align: start;
            flex: var(--flex-grow) 0
              ${isMobile
                ? "75%"
                : `calc(calc(100% / ${desktopCards}) - calc(calc(var(--gap) * ${desktopCards - 1}) / ${desktopCards}))`};
          }

          &:not(:has(:nth-child(${desktopCards}n))) {
            --flex-grow: 0;
          }

          // Hide scrollbar
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }

          &:empty {
            display: none;
          }
        `,
        extraCss,
      ]}
    >
      {children}
    </section>
  );
}

export function StepCard({
  extraCss,
  children,
  onClick,
}: {
  extraCss?: Interpolation<Theme>;
  children: React.ReactNode;
  onClick: VoidFunction;
}) {
  const theme = useTheme();

  return (
    <button
      onClick={onClick}
      className="step-card"
      css={[
        css`
          display: flex;
          justify-content: flex-start;
          white-space: nowrap;
          cursor: pointer;
          border: 1px solid ${theme.colors.stroke["soft-200"]};
          background: ${theme.colors.bg["white-0"]};
          box-shadow: ${theme.shadows.regular.medium};
          border-radius: 999px;
          padding: 8px;
          align-items: center;

          @media (hover: hover) {
            &:hover {
              background: ${theme.colors.bg["weak-50"]};
            }
          }
        `,
        extraCss,
      ]}
    >
      {children}
    </button>
  );
}
