import { css, useTheme } from "@emotion/react";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import { useRef, useState } from "react";

interface SearchProps extends React.HTMLAttributes<HTMLInputElement> {
  onSearch: (query: string) => void;
  placeholder?: string;
  alwaysMaxWidth?: boolean;
  openWidth?: number;
  closedWidth?: number;
}

export const Search = ({
  onSearch,
  placeholder,
  alwaysMaxWidth,
  openWidth = 248,
  closedWidth = 57,
  ...props
}: SearchProps) => {
  const theme = useTheme();
  const iconSize = 20;
  const ref = useRef<HTMLInputElement>(null);
  const [clearable, setClearable] = useState(false);

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: stretch;
        min-width: 40px;
        border-radius: 8px;

        transition: background 0.2s;

        &:hover {
          background: ${theme.colors.bg["weak-50"]};
        }

        &:focus-within {
          background: ${theme.colors.bg["weak-50"]};
        }
      `}
    >
      <input
        ref={ref}
        role="search"
        aria-label="Search"
        {...props}
        onKeyDown={(e) => {
          if (!ref.current) return;
          // To prevent focus listeners above from triggering
          // For example inside DropdownContent
          e.stopPropagation();
          if (e.key === "Escape") {
            ref.current.value = "";
            ref.current.blur();
            setClearable(false);
            onSearch("");
          } else if (e.key === "Enter") {
            ref.current.blur();
          }
        }}
        css={[
          theme.typography["paragraph-s"],
          css`
            border: none;
            outline: none;
            box-shadow: none;
            box-sizing: content-box;
            flex: 1; // Takes up remaining space
            padding: 8px;
            background: transparent;
            width: ${clearable || alwaysMaxWidth
              ? `${openWidth}px`
              : `${closedWidth}px`};

            transition: width 0.2s ease;

            &:focus {
              color: ${theme.colors.text["strong-950"]};
              width: ${openWidth}px;
            }
          `,
        ]}
        placeholder={placeholder ?? "Search..."}
        onChange={(e) => {
          onSearch(e.target.value);
          setClearable(!!e.target.value);
        }}
      />
      <div
        css={css`
          order: -1;
          cursor: text;
          padding-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <MagnifyingGlass
          size={iconSize}
          color={theme.colors.icon["soft-400"]}
        />
      </div>
      {clearable && (
        <div
          className="clear-btn"
          css={css`
            position: absolute;
            z-index: calc(var(--dnd-base-z-index, 0) + 25);
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            color: ${theme.colors.icon["soft-400"]};

            &:hover {
              color: ${theme.colors.icon["sub-600"]};
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation(); // To not trigger focus on the input
            if (ref.current && ref.current.value) {
              ref.current.value = "";
              ref.current.blur();
              setClearable(false);
              onSearch("");
            }
          }}
        >
          <X size={iconSize} />
        </div>
      )}
    </div>
  );
};
