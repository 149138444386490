import * as React from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { css, useTheme } from "@emotion/react";

const ButtonGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    {...props}
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    {children}
  </ToggleGroupPrimitive.Root>
));

ButtonGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ButtonGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  const theme = useTheme();
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      {...props}
      css={[
        theme.typography["label-s"],
        css`
          color: ${theme.colors.text["sub-600"]};
          padding: 6px 14px;
          border: 1px solid ${theme.colors.stroke["soft-200"]};
          border-right: none;
          background: ${theme.colors.bg["white-0"]};

          cursor: pointer;
          &:hover {
            background-color: ${theme.colors.bg["weak-50"]};
          }
          &:focus {
            outline: none;
          }
          &:active {
            background-color: ${theme.colors.bg["weak-50"]};
            color: ${theme.colors.text["strong-950"]};
          }
          &[data-state="on"] {
            background-color: ${theme.colors.bg["weak-50"]};
            color: ${theme.colors.text["strong-950"]};
          }

          &:first-of-type {
            border-radius: 8px 0 0 8px;
          }
          &:last-of-type {
            border-radius: 0 8px 8px 0;
            border-right: 1px solid ${theme.colors.stroke["soft-200"]};
          }
        `,
      ]}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  );
});

ButtonGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ButtonGroup, ButtonGroupItem };
