import { css, useTheme } from "@emotion/react";
import { Image } from "@phosphor-icons/react";
import useIsMobile from "../../../helpers/hooks/useIsMobile";
import { getShopifyImage } from "src/lite/helpers/getShopifyImage";

/**
 * Shows a collection image, consisting of the postar image chosen in shopify, or up to the first three products depending on availability. Or, if none of these exist, a placeholder.
 */
export function CollectionImage({
  coverImages,
  productImages,
  showPlaceholder,
  size,
}: {
  coverImages?: string[];
  productImages?: string[] | null;
  showPlaceholder: boolean;
  size: "small" | "large";
}) {
  const isMobile = useIsMobile();
  let aspectRatioWidthComponent = size === "large" ? 117 : 86;
  let aspectRatioHeightComponent = size === "large" ? 69 : 48;
  if (isMobile) {
    aspectRatioWidthComponent /= 2;
    aspectRatioHeightComponent /= 2;
  }
  const theme = useTheme();
  const [coverImage] = coverImages || [];
  const imagesToShow = coverImage ? [coverImage] : productImages?.slice(0, 3);

  return (
    <div
      css={css`
        width: ${aspectRatioWidthComponent}px;
        flex-shrink: 0;
        border-radius: 6px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(${imagesToShow?.length ?? 1}, 1fr);
      `}
    >
      {imagesToShow ? (
        imagesToShow.map((image) => (
          <div
            key={image}
            css={css`
              aspect-ratio: ${aspectRatioWidthComponent /
              imagesToShow.length /
              aspectRatioHeightComponent};
              position: relative;
              overflow: hidden;
            `}
          >
            <img
              alt="Collection cover image"
              css={css`
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
              `}
              src={getShopifyImage(image, size)}
            />
          </div>
        ))
      ) : (
        <div
          className={!showPlaceholder ? "" : "placeholder"}
          css={css`
            aspect-ratio: ${aspectRatioWidthComponent /
            aspectRatioHeightComponent};
            background: ${theme.colors.bg["weak-50"]};
            position: relative;
            overflow: hidden;

            &.placeholder {
              /* animate skeleton */
              animation: pulse 1.5s infinite;
              @keyframes pulse {
                0% {
                  background: ${theme.colors.bg["weak-50"]};
                }
                50% {
                  background: ${theme.colors.bg["sub-200"]};
                }
                100% {
                  background: ${theme.colors.bg["weak-50"]};
                }
              }
            }
          `}
        >
          {!showPlaceholder && (
            <Image
              size={24}
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: ${theme.colors.icon["disabled-300"]};
              `}
            />
          )}
        </div>
      )}
    </div>
  );
}
