import { css } from "@emotion/react";
import { MagnifyingGlass, SquaresFour } from "@phosphor-icons/react";
import { memo, useCallback, useMemo, useState } from "react";
import { CollectionTable } from "./CollectionTable/CollectionTable";
import { Alert } from "src/alignUI/Alert/Alert";
import { DepictLink } from "../../../alignUI/DepictLink/DepictLink";
import { EmptyState } from "../../../alignUI/EmptyState/EmptyState";
import Container from "src/alignUI/Container/Container";
import useCollectionsWithData from "./useCollectionWithData";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { CollectionOrderBy } from "../../../api/types";
import { CollectionsHeader } from "./CollectionsHeader/CollectionsHeader";
import { useDebounce } from "usehooks-ts";
import { useMutation } from "@tanstack/react-query";
import useActivateCollection from "./useActivateCollection";
import { useAlert } from "../../../alignUI/Alert/useAlert/useAlert";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { getFromPeriod, Period } from "../Dashboard/util";
import useDashboardCollectionData from "../Dashboard/useDashboardCollectionData";
import { useColumnSettings } from "../Settings/ColumnSettings/useColumnSettings";
import * as Sentry from "@sentry/react";

const Collections = () => {
  const { merchantId } = useMerchantId();
  const { addAlert } = useAlert();

  const now = useMemo(() => new Date(), []);
  const [period, setPeriod] = useState<Period>({
    type: "4w",
    fromDate: getFromPeriod("4w", now),
    toDate: now,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [orderBy, setOrderBy] = useState<CollectionOrderBy>("updated_at");

  const collectionsQuery = useCollectionsWithData({
    from_date: period.fromDate,
    to_date: period.toDate,
    page_size: 25,
    search_query: debouncedSearchQuery,
    order_by: orderBy,
  });

  const [loadMoreRef] = useInfiniteScroll({
    hasNextPage: collectionsQuery.hasNextPage,
    loading: collectionsQuery.isLoading,
    onLoadMore: () => {
      collectionsQuery.fetchNextPage();
    },
  });

  const data = useMemo(() => {
    return (
      collectionsQuery.data?.pages.flatMap((page) => page.collections) || []
    );
  }, [collectionsQuery.data]);

  const hasNoCollections =
    collectionsQuery.data?.pages.length === 0 && !collectionsQuery.isLoading;

  const hasNoSearchResults =
    data.length === 0 &&
    debouncedSearchQuery.length > 0 &&
    !collectionsQuery.isLoading &&
    !hasNoCollections;

  const NoRowsOverlay = useMemo(() => {
    if (hasNoSearchResults) {
      return <EmptyState message={"No results found"} icon={MagnifyingGlass} />;
    }

    return (
      <EmptyState
        message={"You don’t have any collections"}
        icon={SquaresFour}
      />
    );
  }, [hasNoSearchResults]);

  const activateCollectionMutation = useActivateCollection();
  const activateCollection = activateCollectionMutation.mutateAsync;

  const { mutate: setDepictUnPublishState } = useMutation({
    mutationFn: async ({ collectionIds }: { collectionIds: string[] }) => {
      try {
        await activateCollection({
          collectionIds,
          merchantId,
          active: false,
        });
      } catch (error) {
        Sentry.captureException(error);
        addAlert({
          text: "Oops! ",
          description:
            "For some reason, we couldn’t un-publish this collection. Try again later, or contact support.",
          status: "error",
          styling: "filled",
          size: "large",
          autoClose: 5000,
        });
        return;
      }

      const text = "Un-published collection";

      addAlert({
        text,
        status: "success",
        styling: "stroke",
        size: "small",
      });
    },
    // Todo refetch collection on un publish make sure the collection is un-published
  });

  const onUnPublishDepict = useCallback(
    async (collectionId: string) => {
      setDepictUnPublishState({
        collectionIds: [collectionId],
      });
    },
    [setDepictUnPublishState]
  );

  const collectionDataQuery = useDashboardCollectionData({
    from_date: period.fromDate,
    to_date: period.toDate,
    order_by: "clicks_desc",
    page_size: 2500,
    disabled: !open,
  });

  const collectionsData = useMemo(
    () => collectionDataQuery.data?.pages?.flatMap((page) => page.collections),
    [collectionDataQuery.data]
  );
  // Hack: load column settings to prevent flashing when opening a collection
  useColumnSettings();

  return (
    <Container
      customCss={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 16px 0;
      `}
    >
      <CollectionsHeader
        period={period}
        setPeriod={setPeriod}
        collectionsData={collectionsData}
        collectionsDataLoading={collectionDataQuery.isLoading}
      />
      {collectionsQuery.isError ? (
        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Alert
            status={"error"}
            styling={"stroke"}
            size={"large"}
            text={"Oops!"}
            description="For some reason, we couldn’t load the page. Try to reload the page. If the problem persists please contact support."
            component={
              <DepictLink
                onClick={() => collectionsQuery.refetch()}
                underline={true}
              >
                Refresh
              </DepictLink>
            }
          />
        </div>
      ) : (
        <>
          <MemoizedCollectionTable
            loadMoreRef={loadMoreRef}
            onSearch={setSearchQuery}
            collections={data}
            isLoading={collectionsQuery.isLoading}
            noRowsOverlayComponent={NoRowsOverlay}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            onUnPublishDepict={onUnPublishDepict}
            collectionsData={collectionsData}
            collectionsDataLoading={collectionDataQuery.isLoading}
            collectionTopClickDataQuery={collectionDataQuery}
          />
        </>
      )}
    </Container>
  );
};

const MemoizedCollectionTable = memo(CollectionTable);

export default Collections;

export const Component = Collections;
