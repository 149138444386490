import useAuthorizedMerchants from "./helpers/hooks/app/useAuthorizedMerchants";
import { AuthorizedMerchantsContext } from "./contexts/AuthorizedMerchants.context";
import DepictLiteApp from "./DepictLiteApp";
import SplashScreen from "./lite/components/SplashScreen/SplashScreen";
import { Suspense } from "react";
import NeueMontreal from "./lite/views/NeueMontreal";
import AlertsContainer from "./alignUI/Alert/AlertsContainer";
import GlobalStyles from "./GlobalStyles";

const App = () => {
  const [authorizedMerchants, loadingMerchants] = useAuthorizedMerchants();

  return (
    <AuthorizedMerchantsContext.Provider
      value={{
        authorizedMerchants,
        loading: loadingMerchants,
      }}
    >
      <Suspense fallback={<SplashScreen />}>
        <NeueMontreal />
        <GlobalStyles />
        <DepictLiteApp />
      </Suspense>
      <AlertsContainer />
    </AuthorizedMerchantsContext.Provider>
  );
};

export default App;
