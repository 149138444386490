import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { type Merchant } from "src/api/types";
import { monitoringEnabled, standalone } from "src/monitoring";

export default function useHotjar(
  authorizedMerchants: Merchant[] | null,
  isSuperUser: boolean,
  userName: string | undefined,
  userEmail: string | undefined
) {
  useEffect(() => {
    if (!monitoringEnabled) {
      return;
    }
    if (standalone) {
      return;
    }
    if (!authorizedMerchants) {
      return;
    }
    if (authorizedMerchants.length === 0) {
      return;
    }
    if (!isSuperUser && userEmail) {
      hotjar.identify(userEmail, {
        userName,
        appVersion: "lite",
      });
    }
  }, [authorizedMerchants, isSuperUser, userEmail, userName]);
}
