import { breakpoints } from "src/theme";
import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [mediaQueryList] = useState(
    window.matchMedia(`(width < ${breakpoints.sm}px)`)
  );
  const [isMobile, setIsMobile] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    mediaQueryList.addEventListener("change", handler);
    return () => mediaQueryList.removeEventListener("change", handler);
  }, [mediaQueryList]);

  return isMobile;
};

export default useIsMobile;
