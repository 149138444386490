/**
 * Caps the size of blocks if they don't fit in the available columns and moves a block until its position is no longer taken, generates CSS properties to position each block.
 *
 * There's a liquid implementation of this exact same logic, please keep them in sync: https://github.com/depict-org/depict/pull/1471
 */
export function positionBlocks<
  T extends {
    index: number;
    span_columns: number;
    span_rows: number;
    DOMElementId: string;
  },
>(
  localContentBlockState: T[],
  showingColumns: number,
  numberOfProducts: number
) {
  const occupiedByBlocks: (string | undefined)[] = [];
  const blockIdToStartRow: Record<string, number> = {};

  const repositionedBlocks = localContentBlockState.map((block) => {
    const blockSpanColumnsPossible = Math.min(
      block.span_columns,
      showingColumns
    );
    let columnStart: number;
    let blockIndex = block.index;
    let doesntFit = false;

    do {
      columnStart = blockIndex % showingColumns;
      // Always assume it will fit this time, unless there are not enough columns available after index where we would want to put it right now
      doesntFit = columnStart + blockSpanColumnsPossible > showingColumns;

      if (!doesntFit) {
        outerForLoop: for (
          let blockRow = 0;
          blockRow < block.span_rows;
          blockRow++
        ) {
          for (
            let blockCol = 0;
            blockCol < blockSpanColumnsPossible;
            blockCol++
          ) {
            const startOfThisRow = blockIndex + blockRow * showingColumns;
            if (occupiedByBlocks[startOfThisRow + blockCol]) {
              // Doesn't fit because a content block already exists in the area that this block would take up
              doesntFit = true;
              break outerForLoop;
            }
          }
        }
      }

      if (doesntFit) blockIndex++;
    } while (doesntFit);

    const numberOfPositionsTakenByBlocks = occupiedByBlocks.filter(
      (cell, index) => cell && index < blockIndex
    ).length;
    const isCutOff =
      numberOfProducts + numberOfPositionsTakenByBlocks <= blockIndex;

    for (let blockRow = 0; blockRow < block.span_rows; blockRow++) {
      for (let blockCol = 0; blockCol < blockSpanColumnsPossible; blockCol++) {
        const startOfThisRow = blockIndex + blockRow * showingColumns;
        occupiedByBlocks[startOfThisRow + blockCol] = block.DOMElementId;
      }
    }

    return {
      /**
       * Whether the block "hangs over" to where there aren't products anymore
       */
      isCutOff,
      block,
    };
  });

  // We create this to be able to know which product to put in what row in DragAndDropGrid
  const occupiedByBlocksOrProducts: (string | number | null)[] = [];
  let loopUntil = numberOfProducts;
  let addedProductIndex = 0;
  for (let i = 0; i < loopUntil; i++) {
    const atIndex = occupiedByBlocks[i];
    if (atIndex) {
      occupiedByBlocksOrProducts.push(atIndex);
      // There's a product card here which is going to displace a product, add an extra product
      loopUntil++;
    } else {
      occupiedByBlocksOrProducts.push(addedProductIndex);
      addedProductIndex++;
    }
  }

  // Fill in nulls in output array for where there are neither blocks or products, so we can show empty slots. And add in empty slots.
  for (let i = loopUntil; i < occupiedByBlocks.length; i++) {
    const atIndex = occupiedByBlocks[i];
    if (atIndex) {
      occupiedByBlocksOrProducts[i] = atIndex;
    } else {
      occupiedByBlocksOrProducts[i] = null;
    }
  }

  // Prune out empty rows because we don't want to show rows not containing anything, remove this if you we want to show empty rows
  pruneLoop: do {
    for (
      let i = 0;
      i < occupiedByBlocksOrProducts.length;
      i += showingColumns
    ) {
      const thisRow = occupiedByBlocksOrProducts.slice(i, i + showingColumns);
      if (thisRow.every((item) => item === null)) {
        // Prune this row because it's empty
        occupiedByBlocksOrProducts.splice(i, showingColumns);
        // Re-start our for loop because now the length of the array and what's at the next index has changed
        continue pruneLoop;
      }
    }
    break;
  } while (true);

  // Update all indexes after pruning
  const repositionedBlocksWithIndex = repositionedBlocks.map(
    (blockInformation) => {
      const {
        block: { DOMElementId },
      } = blockInformation;
      const blockIndex = occupiedByBlocksOrProducts.findIndex(
        (item) => item === DOMElementId
      );
      blockIdToStartRow[DOMElementId] = Math.floor(blockIndex / showingColumns);
      return {
        ...blockInformation,
        finalBlockIndex: blockIndex,
      };
    }
  );

  return {
    repositionedBlocks: repositionedBlocksWithIndex,
    /**
     * occupiedByBlocksOrProducts is an array for every 4x4 slot in the grid where a number is an index of product from the products array to show there, and a string is a unique ID of a content block.
     * This block is the final position, after any displacement.
     */
    occupiedByBlocksOrProducts,
    blockIdToStartRow,
  };
}
