import { DefaultPeriod, getFromPeriod, Period } from "../../Dashboard/util";
import React, { useMemo, useState } from "react";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import { onboardingSteps } from "./FeatureSteps";
import { css, useTheme } from "@emotion/react";
import PageHeader from "../../../../alignUI/PageHeader/PageHeader";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../alignUI/Tooltip/Tooltip";
import { Badge } from "../../../../alignUI/Badges/Badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import { Button } from "../../../../alignUI/Button/Button";
import { CaretDown } from "@phosphor-icons/react";
import TimeFramePicker from "../../Dashboard/TimeFramePicker";
import { JimoBell } from "../JimoBell";
import useFeatureActivationStatus from "../../Dashboard/useFeatureActivationStatus";

export function DatePickerAndTitle({
  featureActivationStatusQuery,
  setPeriod,
  period,
  cancelledSteps,
}: {
  featureActivationStatusQuery: ReturnType<typeof useFeatureActivationStatus>;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  cancelledSteps: string[];
}) {
  const isMobile = useIsMobile();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const now = useMemo(() => new Date(), []);
  const doneSteps = Object.entries(
    featureActivationStatusQuery.data || {}
  ).filter(([key, value]) => {
    const stepTitle = onboardingSteps.find((step) => step.todo === key)?.title;
    if (stepTitle && cancelledSteps.includes(stepTitle)) return false;
    return value;
  }).length;
  const stepsLeft = onboardingSteps.length - doneSteps - cancelledSteps.length;
  const defaultPeriods: DefaultPeriod[] = ["24h", "7d", "4w", "6m", "1y"];
  const theme = useTheme();

  return (
    <PageHeader
      title={
        <>
          {stepsLeft > 0 ? "Get started" : "Overview"}
          {stepsLeft > 0 && !featureActivationStatusQuery.isLoading && (
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <Badge
                  _style="filled"
                  text={stepsLeft + ""}
                  color="blue"
                  styleOverrides={css`
                    background: ${theme.colors.primary.base};
                    border: 1px solid ${theme.colors.primary.base};
                    margin-left: 4px;
                    padding: 0;
                    width: 16px;
                    height: 16px;
                    font-size: 11px;
                    align-self: center;
                  `}
                >
                  {stepsLeft}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                You have {stepsLeft} features left to discover
              </TooltipContent>
            </Tooltip>
          )}
        </>
      }
      outerCss={css`
        padding: 0 16px;
        margin: 0;
      `}
      wrapperCss={css`
        display: flex;
        gap: 12px;
      `}
    >
      {isMobile ? (
        <DropdownMenu
          open={dropdownOpen}
          onOpenChange={(open) => setDropdownOpen(open)}
        >
          <DropdownMenuTrigger asChild>
            <Button
              variant="neutral"
              _style="stroke"
              customCss={[
                theme.typography["label-xs"],
                css`
                  padding: 6px 10px;
                `,
              ]}
            >
              {period.type.toUpperCase()}
              <CaretDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Select timeframe</DropdownMenuLabel>
            {defaultPeriods.map((timeValue) => (
              <DropdownMenuItem
                data-item={timeValue}
                onSelect={({ target }) =>
                  setPeriod({
                    type: (target as HTMLDivElement).dataset
                      .item as DefaultPeriod,
                    fromDate: getFromPeriod(
                      (target as HTMLDivElement).dataset.item as DefaultPeriod,
                      now
                    )!,
                    toDate: now,
                  })
                }
              >
                {timeValue.toUpperCase()}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : (
        <TimeFramePicker now={now} period={period} setPeriod={setPeriod} />
      )}
      <JimoBell />
    </PageHeader>
  );
}
